


























































import { DaoDetailSettingViewModel } from '@/modules/community-organize/viewmodels/dao-detail-setting-viewmodel'
import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    'user-item': () =>
      import(
        '@/modules/community-organize/components/dao-detail-setting/components/member-request/member-request-item.vue'
      ),
    'reject-post-dialog': () =>
      import('@/modules/community-organize/components/dao-detail-setting/dialogs/reject-post-dialog.vue'),
    'reject-reason-dialog': () =>
      import('@/modules/community-organize/components/dao-detail-setting/dialogs/reason-rejection-dialog.vue'),
  },
})
export default class extends Vue {
  @Inject({}) setting!: DaoDetailSettingViewModel

  goBack() {
    this.$router.go(-1)
  }

  reject(item) {
    this.setting.setOpenRejectReasonDialog(true)
    this.setting.setSelectedRequest(item)
  }
}
